import React, { memo, useState } from 'react'
import { areEqual } from 'react-window'
import ReactJsonView from 'react-json-view'
import { Divider, Drawer, Tag, Typography } from 'antd'

import { PlsPipelineRunLog } from '../../../models'
import { parsePipelieRunLogProperty } from '../../../apis/appInsight'

import styles from './index.module.css'

const { Title, Paragraph } = Typography

interface PipelineRunLogViewProps {
  style: React.CSSProperties
  className?: string
  pipelineRunLog: PlsPipelineRunLog
}

const PipelineRunLogView = memo((props: PipelineRunLogViewProps) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(false)

  const { pipelineRunLog } = props

  const message = pipelineRunLog.message || pipelineRunLog.outerMessage

  const content = (
    <div className={`${props.className} ${styles.item}`} style={props.style} onClick={() => setIsDrawerVisible(true)}>
      <div className={styles.line}>
        <span className={styles.status}>
          <Tag color={pipelineRunLog.itemType === 'TRACE' ? 'success' : 'error'}>{pipelineRunLog.itemType}</Tag>
        </span>
        <span className={styles.timestamp}>{pipelineRunLog.timestamp}</span>
      </div>
      <div className={`${styles.line} ${styles.message}`}>{message}</div>
    </div>
  )

  const drawer = (
    <Drawer
      title='Log details'
      placement='right'
      width={1000}
      visible={isDrawerVisible}
      onClose={() => setIsDrawerVisible(false)}
    >
      {pipelineRunLog.message && (
        <>
          <Title level={3}>Message</Title>
          <Paragraph className={styles.pretty}>
            <pre>{pipelineRunLog.message}</pre>
          </Paragraph>
          <Divider />
        </>
      )}
      {pipelineRunLog.outerMessage && (
        <>
          <Title level={3}>Outer message</Title>
          <Paragraph className={styles.pretty}>
            <pre>{pipelineRunLog.outerMessage}</pre>
          </Paragraph>
          <Divider />
        </>
      )}
      {pipelineRunLog.customDimensions && (
        <>
          <Title level={3}>Custom dimensions</Title>
          <ReactJsonView
            src={parsePipelieRunLogProperty(pipelineRunLog.customDimensions) as object}
            displayDataTypes={false}
            displayObjectSize={false}
          />
          <Divider />
        </>
      )}
    </Drawer>
  )

  return (
    <>
      {content}
      {isDrawerVisible && drawer}
    </>
  )
}, areEqual)

export default PipelineRunLogView
