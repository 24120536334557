import React, { memo } from 'react'
import { Button, Empty, Spin, Switch, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

import { PlsPipelineRunLog } from '../../../models'
import Panel from '../../panel'
import PipelineRunLogListView from '../pipelineRunLogList'

import styles from './index.module.css'

interface PipelineRunLogPanelProps {
  className?: string
  isLoading?: boolean
  pipelineRunLogs?: PlsPipelineRunLog[]
  onFetchPipelineRunLogs?: () => void
  showTraceLog?: boolean
  onUpdateShowTraceLog?: (showTraceLog: boolean) => void
}

const PipelineRunLogPanel = memo((props: PipelineRunLogPanelProps) => {
  const hasPipelineRunLogs = props.pipelineRunLogs && props.pipelineRunLogs.length > 0

  return (
    <Panel
      className={props.className}
      title='Pipeline run logs'
      menu={
        <>
          <Tooltip title='Reload pipeline run logs' placement='top'>
            <Button icon={<ReloadOutlined />} onClick={props.onFetchPipelineRunLogs} />
          </Tooltip>
          {props.onUpdateShowTraceLog && (
            <Switch
              className={styles.switch}
              checkedChildren=' Show trace logs'
              unCheckedChildren='Hide trace logs '
              defaultChecked={props.showTraceLog}
              onChange={() => props.onUpdateShowTraceLog!(!props.showTraceLog)}
            />
          )}
        </>
      }
      content={
        props.isLoading ? (
          <Spin size='large' spinning={true || props.isLoading} />
        ) : hasPipelineRunLogs ? (
          <div className={styles.list}>
            <PipelineRunLogListView
              pipelineRunLogs={props.pipelineRunLogs || []}
              showTraceLog={props.showTraceLog || false}
            />
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    />
  )
})

export default PipelineRunLogPanel
