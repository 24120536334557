import React, { memo } from 'react'

import styles from './index.module.css'

interface PanelProps {
  className?: string
  title: string
  menu: React.ReactNode
  content: React.ReactNode
}

const Panel = memo((props: PanelProps) => {
  return (
    <div className={props.className}>
      <div className={styles.menu}>
        <div className={styles.left}>{props.title}</div>
        <div className={styles.right}>{props.menu}</div>
      </div>
      {props.content}
    </div>
  )
})

export default Panel
