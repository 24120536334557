import {
  Subscription,
  DataFactory,
  PlsPipeline,
  PlsPipelineRun,
  ResourceGroup,
  ValueResponseWithContinuationToken,
  ValueResponseWithNextLink,
} from '../models'
import { invokeAzureApi, generateAxiosRequestConfig } from './common'

/**
 * Get subscriptions under IDEAs express route
 * @param accessToken
 */
export async function getSubscriptions(accessToken: string): Promise<Subscription[]> {
  const url = `https://management.azure.com/subscriptions?api-version=2018-06-01`
  const config = generateAxiosRequestConfig(accessToken)

  return await invokeAzureApi<Subscription, ValueResponseWithNextLink<Subscription>>(
    (response) => ({ ...config, url: response?.nextLink || url }),
    (response) => response.nextLink !== undefined
  )
}

/**
 * Get resource groups under IDEAs express route
 * @param accessToken
 */
export async function getResourceGroups(subscriptions: Subscription[], accessToken: string): Promise<ResourceGroup[]> {
  let resourceGroups: ResourceGroup[] = []
  const promises = subscriptions.map(async (subscription) => {
    const url = `https://management.azure.com/${subscription.id}/resourceGroups?api-version=2018-06-01`
    const config = generateAxiosRequestConfig(accessToken)

    return await invokeAzureApi<ResourceGroup, ValueResponseWithNextLink<ResourceGroup>>(
      (response) => ({ ...config, url: response?.nextLink || url }),
      (response) => response.nextLink !== undefined
    )
  })

  ;(await Promise.all(promises)).forEach((item) => {
    resourceGroups = resourceGroups.concat(item)
  })
  return resourceGroups
}

/**
 * Get data factories under specified resource groups
 * @param resourceGroups
 * @param accessToken
 */
export async function getDataFactories(resourceGroups: ResourceGroup[], accessToken: string): Promise<DataFactory[]> {
  let dataFactories: DataFactory[] = []

  const dataFactoryPromises = resourceGroups.map(async (resourceGroup) => {
    const url = `https://management.azure.com${resourceGroup.id}/providers/Microsoft.DataFactory/factories?api-version=2018-06-01`
    const config = generateAxiosRequestConfig(accessToken)

    return invokeAzureApi<DataFactory, ValueResponseWithNextLink<DataFactory>>(
      (response) => ({ ...config, url: response?.nextLink || url }),
      (response) => response.nextLink !== undefined
    )
  })

  ;(await Promise.all(dataFactoryPromises)).forEach((item) => {
    dataFactories = dataFactories.concat(item)
  })

  return dataFactories
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((adf) => {
      return { id: adf.id, name: adf.name }
    })
}

/**
 * Get pipelines under specified data factory
 * @param dataFactoryId
 * @param accessToken
 */
export async function getPipelines(dataFactoryId: string, accessToken: string): Promise<PlsPipeline[]> {
  const url = `https://management.azure.com${dataFactoryId}/pipelines?api-version=2018-06-01`
  const config = generateAxiosRequestConfig(accessToken)

  const pipelines = await invokeAzureApi<PlsPipeline, ValueResponseWithNextLink<PlsPipeline>>(
    (response) => ({ ...config, url: response?.nextLink || url }),
    (response) => response.nextLink !== undefined
  )

  return pipelines
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .map((p) => {
      return { id: p.id, name: p.name, shortName: p.name.substring(p.name.indexOf('_') + 1) }
    })
}

/**
 * Get specified pipeline's executions duing specified period of time
 * @param dataFactoryId
 * @param pipelineName
 * @param lookBackDays
 * @param accessToken
 */
export async function getPipelineRuns(
  dataFactoryId: string,
  pipelineName: string,
  lookBackDays: number,
  accessToken: string,
  _dependentAsync?: {}
): Promise<PlsPipelineRun[]> {
  const lastUpdatedAfter = new Date()
  lastUpdatedAfter.setDate(lastUpdatedAfter.getDate() - lookBackDays)
  const lastUpdatedBefore = new Date()
  lastUpdatedBefore.setDate(lastUpdatedBefore.getDate() + 1)
  const query = {
    lastUpdatedAfter: lastUpdatedAfter.toISOString(),
    lastUpdatedBefore: lastUpdatedBefore.toISOString(),
    filters: [
      {
        operand: 'PipelineName',
        values: [pipelineName],
        operator: 'Equals',
      },
    ],
  }

  const url = `https://management.azure.com${dataFactoryId}/queryPipelineRuns?api-version=2018-06-01`
  const config = generateAxiosRequestConfig(accessToken, 'POST')

  let pipelineRuns = await invokeAzureApi<PlsPipelineRun, ValueResponseWithContinuationToken<PlsPipelineRun>>(
    (response) => ({ ...config, url: url, data: { ...query, continuationToken: response?.continuationToken } }),
    (response) => response.continuationToken !== undefined
  )

  // transform pipeline run's properties
  pipelineRuns = pipelineRuns
    .map((run) => {
      run.parameters.windowStart = new Date(run.parameters.windowStart + ' Z').toISOString()
      return run
    })
    .sort((a, b) =>
      a.parameters.windowStart > b.parameters.windowStart ||
      (a.parameters.windowStart === b.parameters.windowStart && a.runStart > b.runStart)
        ? -1
        : 1
    )

  return pipelineRuns
}

/**
 * Rerun pipeline run
 * @param dataFactoryId
 * @param pipelineName
 * @param pipelineRunId
 * @param accessToken
 */
export async function rerunPipeline(
  dataFactoryId: string,
  pipelineName: string,
  pipelineRunId: string,
  accessToken: string
): Promise<{}> {
  const url = `https://management.azure.com${dataFactoryId}/pipelines/${pipelineName}/createRun?api-version=2018-06-01&referencePipelineRunId=${pipelineRunId}&isRecovery=true`
  const config = generateAxiosRequestConfig(accessToken, 'POST')

  return await invokeAzureApi(
    () => ({ ...config, url: url }),
    () => false
  )
}
