import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../store/index'
import {
  DataFactory,
  KeyValuePair,
  PlsPipeline,
  PlsPipelineFilter,
  PlsPipelineRun,
  PlsPipelineRunFilter,
  PlsPipelineRunLog,
} from '../../models'

import * as Api from '../../apis'

// Store state
interface DebugPage {
  activeTabKey: string
  nextTabKey: number
  tabs: {
    [index: string]: DebugPageTab
  }
}

interface DebugPageTab {
  key: string
  pipelineFilter: PlsPipelineFilter
  // these props needs to rest after switching data factory
  dataFactory?: {
    instance: DataFactory
    pipeline?: {
      instance: PlsPipeline
      run?: PlsPipelineRun
      runs?: PlsPipelineRun[]
      runLogs?: PlsPipelineRunLog[]
      runFilters: PlsPipelineRunFilter
    }
  }
  // these props are shared and have default values
  options: DebugPageTabOption
  asyncStatus: {
    fetchingPipelineRuns?: boolean
    fetchingPipelineRunsRequestId?: string
    fetchingPipelineRunLogs?: boolean
    fetchingPipelineRunLogsRequestId?: string
  }
}

interface DebugPageTabOption {
  lookBackDays: number
  expandPipelineRunGroup: boolean
  showTraceLog: boolean
}

// Store state generator
function NewDebugPageTab(key: number): DebugPageTab {
  return {
    key: `${key}`,
    pipelineFilter: {},
    options: {
      lookBackDays: 10,
      expandPipelineRunGroup: false,
      showTraceLog: true,
    },
    asyncStatus: {},
  }
}

const initialState: DebugPage = {
  activeTabKey: '1',
  nextTabKey: 2,
  tabs: {
    '1': NewDebugPageTab(1),
  },
}

// Async thunks
export const fetchPipelineRuns = createAsyncThunk<
  PlsPipelineRun[],
  { tabKey: string; pipeline: PlsPipeline },
  { state: RootState }
>('debugPage/fetchPipelineRuns', async (arg, thunkApi) => {
  const { dataFactoryCache, debugPage } = thunkApi.getState()
  const tab = debugPage.tabs[debugPage.activeTabKey]
  const dataFactory = tab.dataFactory?.instance
  // stop processing while another request is loading
  if (!dataFactory || tab.asyncStatus.fetchingPipelineRunsRequestId !== thunkApi.requestId) {
    return thunkApi.rejectWithValue(undefined)
  }
  // invoke api
  return await Api.invokeApiAndDisplayMessage<PlsPipelineRun[]>({
    request: async () =>
      await Api.getPipelineRuns(
        dataFactory.id,
        arg.pipeline.name,
        tab.options.lookBackDays,
        dataFactoryCache.accessToken
      ),
    onError: () => thunkApi.rejectWithValue(undefined),
    loadingMessage: `Loading data factory pipeline runs for ${arg.pipeline.name}`,
    successMessage: 'Successfully loaded data factory pipeline runs',
  })
})

export const rerunPipelineRun = createAsyncThunk<
  PlsPipelineRun[],
  { tabKey: string; pipeline: PlsPipeline; pipelineRun: PlsPipelineRun },
  { state: RootState }
>('debugPage/fetchPipelineRuns', async (arg, thunkApi) => {
  const { dataFactoryCache, debugPage } = thunkApi.getState()
  const tab = debugPage.tabs[arg.tabKey]
  const dataFactory = tab.dataFactory?.instance
  // stop processing while another request is loading
  if (!dataFactory || tab.asyncStatus.fetchingPipelineRunsRequestId !== thunkApi.requestId) {
    return thunkApi.rejectWithValue(undefined)
  }
  // invoke api
  return await Api.invokeApiAndDisplayMessage<PlsPipelineRun[]>({
    request: async () =>
      await Api.getPipelineRuns(
        dataFactory.id,
        arg.pipeline.name,
        tab.options.lookBackDays,
        dataFactoryCache.accessToken,
        await Api.rerunPipeline(dataFactory.id, arg.pipeline.name, arg.pipelineRun.runId, dataFactoryCache.accessToken)
      ),
    onError: () => thunkApi.rejectWithValue(undefined),
    loadingMessage: `Sending request to rerun ${arg.pipeline.name}`,
    successMessage: `Successfully create a new run for pipeline ${arg.pipeline.name}`,
  })
})

export const fetchPipelineRunLogs = createAsyncThunk<
  PlsPipelineRunLog[],
  { tabKey: string; pipelineRun: PlsPipelineRun },
  { state: RootState }
>('debugPage/fetchPipelineRunLogs', async (arg, thunkApi) => {
  const { dataFactoryCache, debugPage } = thunkApi.getState()
  const tab = debugPage.tabs[debugPage.activeTabKey]
  // stop processing while another request is loading
  if (tab.asyncStatus.fetchingPipelineRunLogsRequestId !== thunkApi.requestId) {
    return thunkApi.rejectWithValue(undefined)
  }
  // invoke api
  return await Api.invokeApiAndDisplayMessage<PlsPipelineRunLog[]>({
    request: async () => await Api.getPipelineRunLogs(arg.pipelineRun, dataFactoryCache.accessToken),
    onError: () => thunkApi.rejectWithValue(undefined),
    loadingMessage: `Loading data factory pipeline run logs for ${arg.pipelineRun.runId}`,
    successMessage: 'Successfully loaded data factory pipeline run logs',
  })
})

// Create actions and reducer
const debugPageSlice = createSlice({
  name: 'debugPage',
  initialState,
  reducers: {
    // tab operations
    addTab(state) {
      state.tabs[state.nextTabKey] = NewDebugPageTab(state.nextTabKey)
      state.activeTabKey = `${state.nextTabKey}`
      state.nextTabKey = state.nextTabKey + 1
    },
    removeTab(state, action: PayloadAction<string>) {
      if (state.activeTabKey === action.payload) {
        const tabKeys = Object.keys(state.tabs)
        const index = tabKeys.indexOf(action.payload)
        state.activeTabKey = tabKeys[index + 1] || tabKeys[index - 1]
      }
      delete state.tabs[action.payload]
    },
    selectTab(state, action: PayloadAction<string>) {
      state.activeTabKey = action.payload
    },
    // data factory scope
    selectDataFactory(state, action: PayloadAction<DataFactory>) {
      if (action.payload.id !== state.tabs[state.activeTabKey].dataFactory?.instance.id) {
        state.tabs[state.activeTabKey].dataFactory = { instance: action.payload }
      }
    },
    setPipelineFilter<K extends keyof PlsPipelineFilter, V extends PlsPipelineFilter[K]>(
      state: DebugPage,
      action: PayloadAction<KeyValuePair<K, V>>
    ) {
      const tab = state.tabs[state.activeTabKey]
      tab.pipelineFilter[action.payload.key] = action.payload.value
    },
    // pipeline scope
    selectPipeLine(state, action: PayloadAction<PlsPipeline>) {
      const dataFactory = state.tabs[state.activeTabKey].dataFactory
      if (dataFactory && dataFactory.pipeline?.instance.name !== action.payload.name) {
        dataFactory.pipeline = { instance: action.payload, runFilters: {} }
      }
    },
    setPipelineRunFilter<K extends keyof PlsPipelineRunFilter, V extends PlsPipelineRunFilter[K]>(
      state: DebugPage,
      action: PayloadAction<KeyValuePair<K, V>>
    ) {
      const dataFactory = state.tabs[state.activeTabKey].dataFactory
      if (dataFactory && dataFactory.pipeline) {
        dataFactory.pipeline.runFilters[action.payload.key] = action.payload.value
      }
    },
    // pipeline run scope
    selectPipelineRun(state, action: PayloadAction<PlsPipelineRun>) {
      const DebugTab = state.tabs[state.activeTabKey]
      const pipeline = DebugTab.dataFactory?.pipeline
      if (pipeline && pipeline.run?.runId !== action.payload.runId && !DebugTab.asyncStatus.fetchingPipelineRunLogs) {
        pipeline.run = action.payload
      }
    },
    // options
    setOption<K extends keyof DebugPageTabOption, V extends DebugPageTabOption[K]>(
      state: DebugPage,
      action: PayloadAction<KeyValuePair<K, V>>
    ) {
      const tab = state.tabs[state.activeTabKey]
      tab.options[action.payload.key] = action.payload.value
    },
  },
  extraReducers: (builder) => {
    // load pipeline runs
    builder.addCase(fetchPipelineRuns.pending, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (!tab.asyncStatus.fetchingPipelineRuns) {
        tab.asyncStatus.fetchingPipelineRuns = true
        tab.asyncStatus.fetchingPipelineRunsRequestId = action.meta.requestId
      }
    })
    builder.addCase(fetchPipelineRuns.fulfilled, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      const dataFactory = tab.dataFactory
      if (dataFactory && dataFactory.pipeline) {
        dataFactory.pipeline.runs = action.payload
      }
      tab.asyncStatus.fetchingPipelineRuns = false
      tab.asyncStatus.fetchingPipelineRunsRequestId = undefined
    })
    builder.addCase(fetchPipelineRuns.rejected, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (tab.asyncStatus.fetchingPipelineRunsRequestId === action.meta.requestId) {
        tab.asyncStatus.fetchingPipelineRuns = false
        tab.asyncStatus.fetchingPipelineRunsRequestId = undefined
      }
    })
    // load pipeline run logs
    builder.addCase(fetchPipelineRunLogs.pending, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (!tab.asyncStatus.fetchingPipelineRunLogs) {
        tab.asyncStatus.fetchingPipelineRunLogs = true
        tab.asyncStatus.fetchingPipelineRunLogsRequestId = action.meta.requestId
      }
    })
    builder.addCase(fetchPipelineRunLogs.fulfilled, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      const dataFactory = tab.dataFactory
      if (dataFactory && dataFactory.pipeline) {
        dataFactory.pipeline.runLogs = action.payload
      }
      tab.asyncStatus.fetchingPipelineRunLogs = false
      tab.asyncStatus.fetchingPipelineRunLogsRequestId = undefined
    })
    builder.addCase(fetchPipelineRunLogs.rejected, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (tab.asyncStatus.fetchingPipelineRunLogsRequestId === action.meta.requestId) {
        tab.asyncStatus.fetchingPipelineRunLogs = false
        tab.asyncStatus.fetchingPipelineRunLogsRequestId = undefined
      }
    })
  },
})

export const {
  // tab actions
  addTab,
  removeTab,
  selectTab,
  // data factory actions
  selectDataFactory,
  setPipelineFilter,
  // pipeline actions
  selectPipeLine,
  setPipelineRunFilter,
  // pipeline run actions
  selectPipelineRun,
  // tab options
  setOption,
} = debugPageSlice.actions

export default debugPageSlice.reducer
