import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from './index'
import { DataFactory, PlsPipeline } from '../models'
import {
  getSubscriptions,
  getResourceGroups,
  getDataFactories,
  getPipelines,
  invokeApiAndDisplayMessage,
  recordActiveUser,
} from '../apis'

// Store state
interface DataFactoryState {
  accessToken: string
  dataFactories: DataFactory[]
  pipelines: {
    [index: string]: PlsPipeline[]
  }
  asyncStatus: {
    fetchingPipelines?: boolean
    fetchingPipelinesRequestId?: string
  }
}

const initialState: DataFactoryState = {
  accessToken: '',
  dataFactories: [],
  pipelines: {},
  asyncStatus: {},
}

// Async thunks
export const fetchDataFactories = createAsyncThunk<DataFactory[], void, { state: RootState }>(
  'dataFactory/fetchDataFactories',
  async (_, thunkApi) => {
    let token = thunkApi.getState().dataFactoryCache.accessToken
    return await invokeApiAndDisplayMessage<DataFactory[]>({
      request: async () => {
        await recordActiveUser(token)
        const subscriptions = await getSubscriptions(token)
        const resourceGroups = await getResourceGroups(subscriptions, token)
        return await getDataFactories(resourceGroups, token)
      },
      onError: (_) => {
        return thunkApi.rejectWithValue(undefined)
      },
      loadingMessage: 'Loading data factories',
      successMessage: 'Successfully loaded data factories',
    })
  }
)

export interface FetchPipelinesPayload {
  dataFactory: DataFactory
  pipelines: PlsPipeline[]
}

export const fetchPipelines = createAsyncThunk<FetchPipelinesPayload, DataFactory, { state: RootState }>(
  'dataFactory/fetchPipelines',
  async (dataFactory, thunkApi) => {
    const dataFactoryState = thunkApi.getState().dataFactoryCache
    // stop processing if another request comes in while loading
    if (
      dataFactoryState.asyncStatus.fetchingPipelines &&
      dataFactoryState.asyncStatus.fetchingPipelinesRequestId !== thunkApi.requestId
    ) {
      return thunkApi.rejectWithValue(undefined)
    }
    // invoke api
    return await invokeApiAndDisplayMessage<FetchPipelinesPayload>({
      request: async () => ({
        dataFactory: dataFactory,
        pipelines: await getPipelines(dataFactory.id, dataFactoryState.accessToken),
      }),
      onError: () => thunkApi.rejectWithValue(undefined),
      loadingMessage: `Loading data factory pipelines for ${dataFactory.name}`,
      successMessage: 'Successfully loaded data factory pipelines',
    })
  }
)

export const fetchPipelinesFromCache = createAsyncThunk<PlsPipeline[], DataFactory, { state: RootState }>(
  'dataFactory/fetchPipelinesFromCache',
  async (dataFactory: DataFactory, thunkApi) => {
    if (thunkApi.getState().dataFactoryCache.pipelines[dataFactory.id] !== undefined) {
      return thunkApi.rejectWithValue('Loaded')
    }
    const cache = localStorage.getItem(dataFactory.id)
    if (cache === null) {
      thunkApi.dispatch(fetchPipelines(dataFactory))
      return thunkApi.rejectWithValue('NoCache')
    }
    return JSON.parse(cache)
  }
)

// Create actions and reducer
const dataFactorySlice = createSlice({
  name: 'dataFactory',
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      state.accessToken = action.payload
    },
  },
  extraReducers: (builder) => {
    // load data factories
    builder.addCase(fetchDataFactories.fulfilled, (state, action) => {
      state.dataFactories = action.payload
    })
    // load pipelines
    builder.addCase(fetchPipelines.pending, (state, action) => {
      if (!state.asyncStatus.fetchingPipelines) {
        state.asyncStatus.fetchingPipelines = true
        state.asyncStatus.fetchingPipelinesRequestId = action.meta.requestId
      }
    })
    builder.addCase(fetchPipelines.fulfilled, (state, action) => {
      state.pipelines[action.meta.arg.id] = action.payload.pipelines
      state.asyncStatus.fetchingPipelines = false
      state.asyncStatus.fetchingPipelinesRequestId = undefined
    })
    builder.addCase(fetchPipelines.rejected, (state, action) => {
      if (action.meta.requestId === state.asyncStatus.fetchingPipelinesRequestId) {
        state.asyncStatus.fetchingPipelines = false
        state.asyncStatus.fetchingPipelinesRequestId = undefined
      }
    })
    // load pipelines from cache
    builder.addCase(fetchPipelinesFromCache.fulfilled, (state, action) => {
      state.pipelines[action.meta.arg.id] = action.payload
    })
  },
})

export const { setAccessToken } = dataFactorySlice.actions
export default dataFactorySlice.reducer
