import React, { memo, useCallback } from 'react'
import { Empty, Input, Spin, Typography } from 'antd'
import debounce from 'lodash.debounce'

import { KeyValuePair, PlsPipeline, PlsPipelineFilter } from '../../../models'

import Panel from '../../panel'
import PipelineList from '../pipelineList'

import styles from './index.module.css'

const { Text } = Typography

interface PipelinePanelProps {
  className?: string
  isLoading?: boolean
  pipelines?: PlsPipeline[]
  selectedPipeline?: PlsPipeline
  onSelectPipeline?: (pipeline: PlsPipeline) => void
  pipelineFilter: PlsPipelineFilter
  onUpdatePipelineFilter: <K extends keyof PlsPipelineFilter, V extends PlsPipelineFilter[K]>(
    arg: KeyValuePair<K, V>
  ) => void
  menu: React.ReactNode
}

const PipelinePanel = memo((props: PipelinePanelProps) => {
  const { onUpdatePipelineFilter } = props

  const onUpdatePipelineFilterDebounced = useCallback(debounce(onUpdatePipelineFilter, 100), [onUpdatePipelineFilter])

  const updatePipelineNameFilter: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => onUpdatePipelineFilterDebounced({ key: 'name', value: e.target.value }),
    [onUpdatePipelineFilterDebounced]
  )

  return (
    <Panel
      className={props.className}
      title='Pipelines'
      menu={props.menu}
      content={
        props.isLoading ? (
          <Spin size='large' spinning />
        ) : props.pipelines && props.pipelines.length > 0 ? (
          <>
            <Text className={styles.label}>Filter by pipeline name:</Text>
            <Input
              className={styles.input}
              defaultValue={props.pipelineFilter?.name}
              onChange={updatePipelineNameFilter}
            />
            <div className={styles.list}>
              <PipelineList
                pipelines={props.pipelines || []}
                selected={props.selectedPipeline}
                pipelineFilter={props.pipelineFilter}
                onSelectPipeline={props.onSelectPipeline}
              />
            </div>
          </>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    />
  )
})

export default PipelinePanel
