import React, { memo, useCallback, useMemo, useRef } from 'react'
import { VariableSizeGrid, GridChildComponentProps } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { PlsPipeline, PlsPipelineFilter } from '../../../models'
import { getLongerText, getTextWidth } from '../../../utils/helpers'
import { Font } from '../../../utils/consts'

import PipelineView from '../pipelineView'

interface PipelineListProps {
  className?: string
  pipelines: PlsPipeline[]
  selected?: PlsPipeline
  pipelineFilter?: PlsPipelineFilter
  onSelectPipeline?: (pipeline: PlsPipeline) => void
}

const PipelineList = memo((props: PipelineListProps) => {
  const listRef: React.RefObject<VariableSizeGrid> = useRef(null)

  const nameFilter = props.pipelineFilter?.name?.toUpperCase()

  const filteredPipelines = useMemo(() => {
    listRef.current?.resetAfterColumnIndex(0)
    return props.pipelines.filter(
      (pipeline) => nameFilter === undefined || nameFilter === '' || pipeline.name.toUpperCase().includes(nameFilter)
    )
  }, [props.pipelines, nameFilter])

  const cell = useCallback(
    (cellProps: GridChildComponentProps) => {
      const pipeline = filteredPipelines[cellProps.rowIndex]
      if (pipeline === undefined) {
        return <></>
      }
      return (
        <PipelineView
          key={pipeline.id}
          style={cellProps.style}
          pipeline={pipeline}
          isSelected={props.selected?.id === pipeline.id}
          onSelectPipeline={props.onSelectPipeline}
        />
      )
    },
    [filteredPipelines, props.selected, props.onSelectPipeline]
  )

  const rowHeight = useCallback(() => 38, [])

  const columnWidth = useMemo(() => {
    // this may not be accurate since we are not using fixed width font
    const textWidth = getTextWidth(filteredPipelines.map((x) => x.shortName).reduce(getLongerText, ''), Font)
    // 24 is the paading size
    return 24 + (textWidth ? textWidth : 0)
  }, [filteredPipelines])

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VariableSizeGrid
          ref={listRef}
          height={height}
          width={width}
          columnCount={1}
          columnWidth={() => (columnWidth >= width ? columnWidth : width)}
          estimatedColumnWidth={columnWidth >= width ? columnWidth : width}
          rowCount={filteredPipelines.length}
          rowHeight={rowHeight}
          estimatedRowHeight={38}
        >
          {cell}
        </VariableSizeGrid>
      )}
    </AutoSizer>
  )
})

export default PipelineList
