import React, { memo, useCallback, useMemo } from 'react'
import { VariableSizeGrid, GridChildComponentProps } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'

import { PlsPipelineRunLog } from '../../../models'
import { getLongerText, getTextWidth } from '../../../utils/helpers'
import { Font } from '../../../utils/consts'

import PipelineRunLogView from '../pipelineRunLogView'

// component for group list
export interface PipelineRunGroupListViewPorps {
  className?: string
  pipelineRunLogs?: PlsPipelineRunLog[]
  showTraceLog: boolean
}

const PipelineRunLogListView = memo((props: PipelineRunGroupListViewPorps) => {
  const pipelineRunlogs = props.pipelineRunLogs || []

  const filteredPipelineRunLogs = useMemo(
    () =>
      props.showTraceLog
        ? pipelineRunlogs
        : pipelineRunlogs.filter((pipelineRunLog) => pipelineRunLog.itemType !== 'TRACE'),
    [pipelineRunlogs, props.showTraceLog]
  )

  const cell = useCallback(
    (cellProps: GridChildComponentProps) => {
      const pipelineRunLog = filteredPipelineRunLogs[cellProps.rowIndex]
      if (pipelineRunLog === undefined) {
        return <div style={cellProps.style}></div>
      }
      return <PipelineRunLogView style={cellProps.style} pipelineRunLog={pipelineRunLog} />
    },
    [filteredPipelineRunLogs]
  )

  const rowHeight = () => 70

  const columnWidth = useMemo(() => {
    // this may not be accurate since we are not using fixed width font
    const textWidth = getTextWidth(
      filteredPipelineRunLogs.map((x) => x.message || x.outerMessage).reduce(getLongerText, ''),
      Font
    )
    // 24 + 2 is the paading size, add 4px as buffers
    return 24 + 2 + 4 + (textWidth ? textWidth : 0)
  }, [filteredPipelineRunLogs])

  return (
    <AutoSizer>
      {({ height, width }) => (
        <VariableSizeGrid
          height={height}
          width={width}
          columnCount={1}
          columnWidth={() => (columnWidth < width ? width : columnWidth < 1000 ? columnWidth : 1000)}
          estimatedColumnWidth={columnWidth < width ? width : columnWidth < 1000 ? columnWidth : 1000}
          rowCount={filteredPipelineRunLogs.length}
          rowHeight={rowHeight}
          estimatedRowHeight={70}
        >
          {cell}
        </VariableSizeGrid>
      )}
    </AutoSizer>
  )
})

export default PipelineRunLogListView
