import React from 'react'
import { Link, Redirect, Route, Switch, useRouteMatch, useLocation } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Layout, Menu, message } from 'antd'
import { NodeExpandOutlined, ToolOutlined } from '@ant-design/icons'

import configureStore from './store'
import DebugPage from './pages/pipeline'
import RestatementPage from './pages/restatement'

import styles from './app.module.css'

const { Sider } = Layout

message.config({
  top: -5,
  maxCount: 1,
})

const store = configureStore()

const App = () => {
  let match = useRouteMatch()
  let location = useLocation()

  let debugPath = `${match.url}debug`
  let restatementPath = `${match.url}restatement`

  return (
    <Layout className={styles.app}>
      <Sider defaultCollapsed>
        <h1 className={styles['app-logo']}>PLS</h1>

        <Menu selectedKeys={[location.pathname]} theme='dark'>
          <Menu.Item key={debugPath} icon={<NodeExpandOutlined />}>
            <Link to={debugPath}>Pipelines</Link>
          </Menu.Item>
          <Menu.Item key={restatementPath} icon={<ToolOutlined />}>
            <Link to={restatementPath}>Restatements</Link>
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout>
        <Switch>
          <Route exact path={match.url}>
            <Redirect to={debugPath} />
          </Route>
          <Route exact path={debugPath}>
            <Provider store={store}>
              <DebugPage />
            </Provider>
          </Route>
          <Route exact path={restatementPath}>
            <Provider store={store}>
              <RestatementPage />
            </Provider>
          </Route>
        </Switch>
      </Layout>
    </Layout>
  )
}

export default App
