import AppConfig from '../config'
import { AppInsightQueryResponse, PlsPipelineRun, PlsPipelineRunLog, PlsPipelineRunLogProperty } from '../models'
import { invokeApi, generateAxiosRequestConfig } from './common'

export function parsePipelieRunLogProperty(json: string): PlsPipelineRunLogProperty {
  let parsedJson: { [key: string]: string | PlsPipelineRunLogProperty }
  try {
    parsedJson = JSON.parse(json)
    Object.keys(parsedJson).forEach((key) => {
      if (typeof parsedJson[key] === 'string') {
        parsedJson[key] = parsePipelieRunLogProperty(parsedJson[key] as string)
      }
    })
    return parsedJson
  } catch {
    return json
  }
}

export async function getPipelineRunLogs(
  pipelineRun: PlsPipelineRun,
  accessToken: string
): Promise<PlsPipelineRunLog[]> {
  const { runId, runStart, runEnd } = pipelineRun

  const startTimeCondition = runStart ? `| where timestamp >= datetime(${runStart})` : ''
  const endTimeCOndition = runEnd ? `| where timestamp <= datetime(${runEnd})` : ''
  const query = `union (traces), (exceptions) ${startTimeCondition} ${endTimeCOndition} | where customDimensions has '${runId}' | project timestamp, itemType, message, outerMessage, customDimensions | order by timestamp desc`

  const response = await getAppInsightLogs(query, accessToken)

  return response.tables.flatMap( (table) => table.rows.map((row) => ({
    timestamp: row[0],
    itemType: row[1].toUpperCase(),
    message: row[2],
    outerMessage: row[3],
    customDimensions: row[4],
  })));
}

export async function getAppInsightLogs(query: string, accessToken: string): Promise<AppInsightQueryResponse> {
  const url = `${AppConfig.PLSApiDomain.HOST}/api/v1/telemetry/queryPipelineRunsLogs?query=${query}`
  const config = generateAxiosRequestConfig(accessToken)

  return await invokeApi<AppInsightQueryResponse>({ ...config, url: url })
}
