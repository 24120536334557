import React, { memo, useState, useCallback } from 'react'
import { Dropdown, Menu, Tag, Modal, Typography } from 'antd'
import {
  LinkOutlined,
  UnorderedListOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  InfoCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons'

import { PlsPipeline, PlsPipelineStatus } from '../../../models'
import { openPipelineInADF } from '../../../utils/helpers'

import styles from './index.module.css'

const { Text } = Typography

function getPipelineStatusTag(status: PlsPipelineStatus) {
  switch (status) {
    case 'Succeeded':
      return <Tag className={styles.statusTag} color='success' icon={<CheckCircleOutlined />} />
    case 'InProgress':
      return <Tag className={styles.statusTag} color='processing' icon={<SyncOutlined spin />} />
    case 'Warning':
      return <Tag className={styles.statusTag} color='error' icon={<InfoCircleOutlined />} />
    case 'Cancelled':
    default:
      return <Tag className={styles.statusTag} color='default' icon={<MinusCircleOutlined />} />
  }
}

interface PipelineViewProps {
  style?: React.CSSProperties
  pipeline: PlsPipeline
  isSelected: boolean
  showStatus?: boolean
  showGaps?: boolean
  onSelectPipeline?: (pipeline: PlsPipeline) => void
}

const PipelineView = memo((props: PipelineViewProps) => {
  const [isGapsModalVisiable, setIsGapsModalVisible] = useState(false)

  const hideGapsModal = useCallback(() => setIsGapsModalVisible(false), [setIsGapsModalVisible])
  const showGapsModal = useCallback(() => setIsGapsModalVisible(true), [setIsGapsModalVisible])

  const content = (
    <div
      className={`${styles.pipeline} ${props.isSelected ? styles.selected : ''}`}
      style={props.style}
      onClick={() => props.onSelectPipeline && props.onSelectPipeline(props.pipeline)}
    >
      {props.showStatus && props.pipeline.status && getPipelineStatusTag(props.pipeline.status)}
      {props.pipeline.shortName}
    </div>
  )

  const showGaps = props.showGaps && props.pipeline.gaps && props.pipeline.gaps.length > 0

  const contentAndMenu = (
    <Dropdown
      trigger={['contextMenu']}
      overlay={() => (
        <Menu>
          <Menu.Item key='1' onClick={() => openPipelineInADF(props.pipeline)}>
            <LinkOutlined />
            &nbsp;&nbsp;View details in ADF
          </Menu.Item>
          {showGaps && (
            <Menu.Item key='1' onClick={showGapsModal}>
              <UnorderedListOutlined />
              &nbsp;&nbsp;Show gaps
            </Menu.Item>
          )}
        </Menu>
      )}
    >
      {content}
    </Dropdown>
  )

  let gapsModal = <></>
  if (showGaps) {
    gapsModal = (
      <Modal
        title="Pipeline run window slices that hasn't completed"
        visible={isGapsModalVisiable}
        onOk={hideGapsModal}
        onCancel={hideGapsModal}
      >
        {props.pipeline.gaps!.map((windowStart, index) => (
          <Text key={index} className={styles.gap}>
            {windowStart}
          </Text>
        ))}
      </Modal>
    )
  }

  return (
    <>
      {contentAndMenu}
      {gapsModal}
    </>
  )
})

export default PipelineView
