import React, { memo, useMemo } from 'react'
import { Empty, Spin, Typography } from 'antd'
import { UserOutlined, ThunderboltOutlined, PlayCircleOutlined } from '@ant-design/icons'

import { PlsPipeline, Restatement } from '../../../models'

import Panel from '../../panel'
import PipelineView from '../../pipeline/pipelineView'

import styles from './index.module.css'

const { Text } = Typography

interface RestatementPanelProps {
  className?: string
  isLoading?: boolean
  restatement?: Restatement
  pipelines?: PlsPipeline[]
  selectedPipeline?: PlsPipeline
  onSelectPipeline: (pipeline: PlsPipeline) => void
  menu: React.ReactNode
}

const RestatementPanel = memo((props: RestatementPanelProps) => {
  const { restatement, pipelines } = props

  const dataFactoryPipelines = useMemo(() => {
    const dataFactoryPipelines: { [dataFactory: string]: PlsPipeline[] } = {}
    for (const pipeline of pipelines || []) {
      const dataFactory = pipeline.name.substring(0, pipeline.name.indexOf('_'))
      if (dataFactoryPipelines[dataFactory] === undefined) {
        dataFactoryPipelines[dataFactory] = []
      }
      dataFactoryPipelines[dataFactory].push(pipeline)
    }
    return dataFactoryPipelines
  }, [pipelines])

  return (
    <Panel
      className={props.className}
      title='Restatement'
      menu={props.menu}
      content={
        props.isLoading ? (
          <Spin size='large' spinning />
        ) : props.restatement ? (
          <div className={styles.list}>
            <div className={styles.listInner}>
              <Text className={styles.section}>Info:</Text>
              <Text className={styles.label}>
                <UserOutlined />
                &nbsp;&nbsp;{restatement?.userName}
              </Text>
              <Text className={styles.label}>
                <ThunderboltOutlined />
                &nbsp;&nbsp;{restatement?.restatementId}
              </Text>
              <Text className={styles.label}>
                <PlayCircleOutlined />
                &nbsp;&nbsp;{restatement?.requestTime}
              </Text>

              {Object.keys(dataFactoryPipelines).map((dataFactory) => (
                <div key={dataFactory}>
                  <Text className={styles.section}>{dataFactory}:</Text>
                  {dataFactoryPipelines[dataFactory].map((pipeline) => (
                    <PipelineView
                      key={pipeline.id}
                      pipeline={pipeline}
                      isSelected={props.selectedPipeline?.id === pipeline.id}
                      onSelectPipeline={props.onSelectPipeline}
                      showStatus
                      showGaps
                    />
                  ))}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    />
  )
})

export default RestatementPanel
