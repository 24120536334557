import React, { useCallback, useState, memo } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Divider, Input, Popover, Steps, Typography } from 'antd'
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons'

import { useTypedSelector } from '../../store'
import { setAccessToken, fetchDataFactories } from '../../store/dataFactory'

import styles from './index.module.css'

const { Text } = Typography

interface AccessTokenFormProps {
  onSubmit: () => void
  description: string
}

interface AccessTokenFormDropdownProps {
  description: string
}

export const AccessTokenForm = memo((props: AccessTokenFormProps) => {
  const { onSubmit, description } = props

  const token = useTypedSelector((state) => state.dataFactoryCache.accessToken)

  // use dispatch
  const dispatch = useDispatch()

  const onAccessTokenChanged: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      dispatch(setAccessToken(e.target.value))
    },
    [dispatch]
  )

  const onClickUseAccessToken: React.MouseEventHandler<HTMLElement> = useCallback(
    (_) => {
      onSubmit()
      dispatch(fetchDataFactories())
    },
    [dispatch, onSubmit]
  )

  // render
  return (
    <div className={styles.root}>
      <div className={styles.form}>
        <Input.Password
          className={styles.pwd}
          placeholder='Paste your access token here'
          visibilityToggle={false}
          defaultValue={token}
          onChange={onAccessTokenChanged}
        />
        <Button className={styles.pwdBtn} type='primary' onClick={onClickUseAccessToken}>
          {description}
        </Button>
      </div>

      <Divider>How to get access token</Divider>

      <Steps direction='vertical' current={-1}>
        <Steps.Step title='Open terminal' />
        <Steps.Step
          title={
            <span>
              Login your Torus debug account <Text code> alias_debug@prdtrs01.prod.outlook.com</Text> by executing
              <Text code copyable={{ icon: <CopyOutlined /> }}>
                az login
              </Text>
            </span>
          }
          description='(Optional) you only need to execute this once, the session is restored when you reopen terminal'
        />
        <Steps.Step
          title={
            <span>
              Copy token into your clipboard by executing
              <br />
              <Text code copyable={{ icon: <CopyOutlined /> }}>
                az account get-access-token | ConvertFrom-Json | select -ExpandProperty accessToken | Set-Clipboard
              </Text>
            </span>
          }
        />
        <Steps.Step title='Paste token in the form above' />
        <Steps.Step title='The token will expire in one hour, you will need to repeat the steps above' />
      </Steps>
    </div>
  )
})

export const AccessTokenFormDropdown = memo((props: AccessTokenFormDropdownProps) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false)

  return (
    <Popover
      title={`Use access token to ${props.description.toLowerCase()} that you can view`}
      trigger='click'
      placement='bottomLeft'
      visible={isPopoverVisible}
      onVisibleChange={() => setIsPopoverVisible(!isPopoverVisible)}
      content={<AccessTokenForm onSubmit={() => setIsPopoverVisible(false)} description={props.description} />}
    >
      <Button className={styles.dropdown} type='ghost' icon={<InfoCircleOutlined />}>
        {' '}
        {props.description}
      </Button>
    </Popover>
  )
})
