import AppConfig from '../config'
import { Restatement } from '../models'
import { generateAxiosRequestConfig, invokeApi } from './common'

export async function GetRestatement(dataFactoryName: string, id: string, accessToken: string): Promise<Restatement> {
  const url = `${AppConfig.PLSApiDomain.HOST}/api/v1/restatement/${id}?dataFactoryName=${dataFactoryName}`
  const config = generateAxiosRequestConfig(accessToken)
  let result = await invokeApi<Restatement>({ ...config, url: url })
  for (const trigger in result.triggeredPipelineRuns) {
    for (const pipeline in result.triggeredPipelineRuns[trigger]) {
      for (const run of result.triggeredPipelineRuns[trigger][pipeline]) {
        run.parameters.windowStart = new Date(run.parameters.windowStart + ' Z').toISOString()
      }
    }
  }
  for (const pipeline in result.pipelineStatus) {
    result.pipelineStatus[pipeline].windowStartGaps = result.pipelineStatus[pipeline].windowStartGaps.sort((a, b) =>
      a > b ? -1 : 1
    )
  }
  return result
}
