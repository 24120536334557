import { AsyncThunkPayloadCreatorReturnValue } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { message } from 'antd'

// Axios & Redux thunk helper functions
export function handleErrorMessage(error: AxiosError): void {
  const status = error.response?.status
  switch (status) {
    case 401:
      message.error('Access token is invalid or has expired. Please enter a new token', 5)
      break
    case 429:
      message.error('Request rate too large. Please retry.', 5)
      break
    default:
      message.error(`Unknown error:\n${error.message}`, 5)
  }
}

export async function invokeApiAndDisplayMessage<Returned, ThunkApiConfig = {}>(arg: {
  request: () => Promise<Returned>
  onError: (error: AxiosError) => AsyncThunkPayloadCreatorReturnValue<Returned, ThunkApiConfig>
  loadingMessage: string | React.ReactNode
  successMessage: string | React.ReactNode
}): Promise<AsyncThunkPayloadCreatorReturnValue<Returned, ThunkApiConfig>> {
  const closeLoadingMessage = message.loading(arg.loadingMessage, 0)
  let hasError = false

  try {
    return await arg.request()
  } catch (error) {
    hasError = true
    handleErrorMessage(error)
    return arg.onError(error)
  } finally {
    closeLoadingMessage()
    if (!hasError) {
      message.success(arg.successMessage, 5)
    }
  }
}
