import React, { useCallback, memo } from 'react'
import { useDispatch, batch } from 'react-redux'
import { Button, Row, Col, Tooltip } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

import {
  DataFactory,
  KeyValuePair,
  PlsPipeline,
  PlsPipelineFilter,
  PlsPipelineRun,
  PlsPipelineRunFilter,
} from '../../models'
import { useTypedSelector } from '../../store'
import {
  selectDataFactory,
  selectPipeLine,
  selectPipelineRun,
  setPipelineFilter,
  setPipelineRunFilter,
  setOption,
  fetchPipelineRuns,
  fetchPipelineRunLogs,
  rerunPipelineRun,
} from './store'
import { fetchPipelines, fetchPipelinesFromCache } from '../../store/dataFactory'

import PipelinePanel from '../../components/pipeline/pipelinePanel'
import PipelineRunPanel from '../../components/pipelineRun/pipelineRunPanel'
import PipelineRunLogPanel from '../../components/pipelineRunLog/pipelineRunLogPanel'
import DataFactorySelect from '../../components/dataFactory/dataFactorySelect'

import styles from './index.module.css'

interface DebugTabViewProps {
  tabKey: string
}

const DebugTabView = memo((props: DebugTabViewProps) => {
  // use store
  const dataFactories = useTypedSelector((state) => state.dataFactoryCache.dataFactories)

  const { pipelines: allPipelines, asyncStatus: dataFactoryAsyncStatus } = useTypedSelector(
    (state) => state.dataFactoryCache
  )

  const tab = useTypedSelector((state) => state.debugPage.tabs[props.tabKey])
  const dataFactory = tab.dataFactory?.instance
  const pipelines = dataFactory?.id ? allPipelines[dataFactory.id] : []
  const pipeline = tab.dataFactory?.pipeline?.instance
  const pipelineFilter = tab.pipelineFilter
  const pipelineRun = tab.dataFactory?.pipeline?.run
  const pipelineRuns = tab.dataFactory?.pipeline?.runs
  const pipelineRunFilter = tab.dataFactory?.pipeline?.runFilters
  const pipelineRunLogs = tab.dataFactory?.pipeline?.runLogs

  // use dispatch
  const dispatch = useDispatch()

  // pipeline operations
  const onSelectDataFactory = useCallback(
    (dataFactory: DataFactory) =>
      batch(() => {
        dispatch(selectDataFactory(dataFactory))
        dispatch(fetchPipelinesFromCache(dataFactory))
      }),
    [dispatch]
  )

  const onSelectPipeline = useCallback(
    (pipeline: PlsPipeline) =>
      batch(() => {
        dispatch(selectPipeLine(pipeline))
        dispatch(fetchPipelineRuns({ tabKey: props.tabKey, pipeline: pipeline }))
      }),
    [dispatch, props.tabKey]
  )

  const onSelectPipelineRunAndViewLogs = useCallback(
    (run: PlsPipelineRun) =>
      batch(() => {
        dispatch(selectPipelineRun(run))
        dispatch(fetchPipelineRunLogs({ tabKey: props.tabKey, pipelineRun: run }))
      }),
    [dispatch, props.tabKey]
  )

  const onUpdatePipelineFilter = useCallback(
    <K extends keyof PlsPipelineFilter, V extends PlsPipelineFilter[K]>(arg: KeyValuePair<K, V>) =>
      dispatch(setPipelineFilter(arg)),
    [dispatch]
  )

  const onUpdatePipelineRunFilter = useCallback(
    <K extends keyof PlsPipelineRunFilter, V extends PlsPipelineRunFilter[K]>(arg: KeyValuePair<K, V>) =>
      dispatch(setPipelineRunFilter(arg)),
    [dispatch]
  )

  const onFetchPipelines = useCallback(() => dataFactory && dispatch(fetchPipelines(dataFactory)), [
    dispatch,
    dataFactory,
  ])

  const onFetchPipelineRuns = useCallback(() => {
    if (pipeline) {
      dispatch(fetchPipelineRuns({ tabKey: props.tabKey, pipeline: pipeline }))
    }
  }, [dispatch, props.tabKey, pipeline])

  const onFetchPipelineRunLogs = useCallback(() => {
    if (pipelineRun) {
      dispatch(fetchPipelineRunLogs({ tabKey: props.tabKey, pipelineRun: pipelineRun }))
    }
  }, [dispatch, props.tabKey, pipelineRun])

  const onRerunPipelineRun = useCallback(
    (pipelineRun: PlsPipelineRun) => {
      if (pipeline) {
        dispatch(
          rerunPipelineRun({
            tabKey: props.tabKey,
            pipeline: pipeline,
            pipelineRun: pipelineRun,
          })
        )
      }
    },
    [dispatch, props.tabKey, pipeline]
  )

  const onUpdateLookBackDays = useCallback(
    (lookBackDays: number | string | undefined) => {
      if (typeof lookBackDays === 'number') {
        dispatch(setOption({ key: 'lookBackDays', value: lookBackDays }))
      }
    },
    [dispatch]
  )

  const onUpdateExpandPipelineRunGroup = useCallback(
    (expandPipelineRunGroup: boolean) =>
      dispatch(setOption({ key: 'expandPipelineRunGroup', value: expandPipelineRunGroup })),
    [dispatch]
  )

  const onUpdateShowTraceLog = useCallback(
    (showTraceLog: boolean) => dispatch(setOption({ key: 'showTraceLog', value: showTraceLog })),
    [dispatch]
  )

  const pipelinePanelMenu = (
    <>
      <Tooltip title={`${pipelines && pipelines.length > 0 ? 'Reload' : 'Load'} pipelines`} placement='top'>
        <Button icon={<ReloadOutlined />} onClick={onFetchPipelines} />
      </Tooltip>
      <DataFactorySelect
        className={styles.select}
        selectedDataFactory={dataFactory}
        dataFactories={dataFactories}
        onSelectDataFactory={onSelectDataFactory}
      />
    </>
  )

  // render
  return (
    <Row className={styles.tabContent}>
      <Col flex='5' className={styles.pipelineColumn}>
        <PipelinePanel
          className={styles.panel}
          isLoading={dataFactoryAsyncStatus.fetchingPipelines || false}
          pipelines={pipelines}
          selectedPipeline={pipeline}
          onSelectPipeline={onSelectPipeline}
          pipelineFilter={pipelineFilter}
          onUpdatePipelineFilter={onUpdatePipelineFilter}
          menu={pipelinePanelMenu}
        />
      </Col>
      <Col flex='7' className={styles.pipelineRunColumn}>
        <PipelineRunPanel
          className={styles.panel}
          isLoading={tab.asyncStatus.fetchingPipelineRuns || false}
          pipelineRuns={pipelineRuns}
          selectedPipelineRun={pipelineRun}
          onFetchPipelineRuns={onFetchPipelineRuns}
          onSelectPipelineRun={onSelectPipelineRunAndViewLogs}
          onViewPipelineRunLogs={onSelectPipelineRunAndViewLogs}
          onRerunPipelineRun={onRerunPipelineRun}
          pipelineRunFilter={pipelineRunFilter}
          onUpdatePipelineRunFilter={onUpdatePipelineRunFilter}
          lookBackDays={tab.options.lookBackDays}
          onUpdateLookBackDays={onUpdateLookBackDays}
          expandPipelineRunGroup={tab.options.expandPipelineRunGroup}
          onUpdateExpandPipelineRunGroup={onUpdateExpandPipelineRunGroup}
        />
      </Col>
      <Col flex='14' className={styles.pipelineRunLogColumn}>
        <PipelineRunLogPanel
          className={styles.panel}
          isLoading={tab.asyncStatus.fetchingPipelineRunLogs || false}
          pipelineRunLogs={pipelineRunLogs}
          onFetchPipelineRunLogs={onFetchPipelineRunLogs}
          showTraceLog={tab.options.showTraceLog}
          onUpdateShowTraceLog={onUpdateShowTraceLog}
        />
      </Col>
    </Row>
  )
})

export default DebugTabView
