import React from 'react'
import { Tag } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, SyncOutlined } from '@ant-design/icons'

import { PipelineRunStatus, PlsPipelineRunColor } from '../../models'

export type PipelineRunStatusSummary = {
  [key in PipelineRunStatus]?: number
}

export function getPipelineRunColor(pipelineRunStatus: PipelineRunStatus): PlsPipelineRunColor {
  switch (pipelineRunStatus) {
    case 'Succeeded':
      return 'success'
    case 'InProgress':
    case 'Queued':
      return 'processing'
    case 'Failed':
      return 'error'
    case 'Cancelled':
    default:
      return 'default'
  }
}

const SucceededIcon = <CheckCircleOutlined />
const InProgressIcon = <SyncOutlined spin />
const FailedIcon = <CloseCircleOutlined />
const DefaultIcon = <MinusCircleOutlined />
export function getPipelineRunIcon(pipelineRunStatus: PipelineRunStatus): React.ReactNode {
  switch (pipelineRunStatus) {
    case 'Succeeded':
      return SucceededIcon
    case 'InProgress':
    case 'Queued':
      return InProgressIcon
    case 'Failed':
      return FailedIcon
    case 'Cancelled':
    default:
      return DefaultIcon
  }
}

export function getPipelineRunTag(pipelineRunStatus: PipelineRunStatus, content: number | string | React.ReactNode) {
  return (
    <Tag
      key={pipelineRunStatus}
      icon={getPipelineRunIcon(pipelineRunStatus)}
      color={getPipelineRunColor(pipelineRunStatus)}
    >
      {content}
    </Tag>
  )
}
