import React, { useCallback, memo } from 'react'
import { useDispatch } from 'react-redux'
import { Empty, Tabs } from 'antd'

import { useTypedSelector } from '../../store'
import { addTab, removeTab, selectTab } from './store'

import { AccessTokenFormDropdown } from '../../components/token'

import RestatementTabView from './tab'

type AntdTabsOnEditEventHandler = (
  activeKey: React.MouseEvent | React.KeyboardEvent | string,
  action: 'add' | 'remove'
) => void
type AntdTabsOnChangeEventHandler = (activeKey: string) => void

const EmptyContent = (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={
      <>
        <span>There is no available data factories.</span>
        <br />
        <span>Click the "Load data factories" button on the top left corner.</span>
      </>
    }
  />
)

const TabContent = {
  left: <AccessTokenFormDropdown description='Load data factories' />,
}

const DebugPage = memo(() => {
  // use state
  const { dataFactories } = useTypedSelector((state) => state.dataFactoryCache)

  const { activeTabKey, tabs } = useTypedSelector((state) => state.restatementPage)

  // use dispatch
  const dispatch = useDispatch()

  // tab operations
  const onEdit: AntdTabsOnEditEventHandler = useCallback(
    (key, action) => {
      if (action === 'add') dispatch(addTab())
      else dispatch(removeTab(key as string))
    },
    [dispatch]
  )

  const onChange: AntdTabsOnChangeEventHandler = useCallback((key) => dispatch(selectTab(key)), [dispatch])

  // render
  return (
    <Tabs
      className='pls-tabs'
      type='editable-card'
      activeKey={activeTabKey}
      tabBarExtraContent={TabContent}
      onEdit={onEdit}
      onChange={onChange}
    >
      {Object.keys(tabs).map((key) => (
        <Tabs.TabPane tab={tabs[key].dataFactory?.name || 'New tab'} key={key} closable={Object.keys(tabs).length > 1}>
          {dataFactories && dataFactories.length > 0 ? <RestatementTabView tabKey={key} /> : EmptyContent}
        </Tabs.TabPane>
      ))}
    </Tabs>
  )
})

export default DebugPage
