import axios, { AxiosResponse, AxiosRequestConfig } from 'axios'

import { ValueResponse } from '../models'

export function generateAxiosRequestConfig(accessToken: string, method: string = 'GET') {
  const config: AxiosRequestConfig = {
    method: method,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
  }
  return config
}

/**
 * Invoke azure rest apis which usually response data in `value` array
 * @param config a function that
 *  1. takes the api's response body as parameter, the value is `undefined` for the initial request
 *  2. then transform the reponse body to a axios configuration object that can be used to invoke the api
 * @param shouldContinue a function that
 *  1. take previous api call's response data
 *  2. then decides whether continue
 */
export async function invokeAzureApi<T, R extends ValueResponse<T>>(
  config: (previousResponse?: R) => AxiosRequestConfig,
  shouldContinue: (previousResponse: R) => boolean
): Promise<T[]> {
  let result: T[] = []
  let response: AxiosResponse<R> | undefined

  do {
    response = await axios.request<R>(config(response?.data))
    result = result.concat(response.data.value)
  } while (shouldContinue(response.data))

  return result
}

export async function invokeApi<R>(config: AxiosRequestConfig): Promise<R> {
  let response = await axios.request<R>(config)
  return response.data
}
