import React, { memo, useMemo, useState } from 'react'
import { PlsPipelineRun } from '../../../models'

import { PipelineRunStatusSummary } from '../utils'
import { PipelineRunGroupListPorps } from '../pipelineRunGroupList'
import PipelineRunView from '../pipelineRunView'
import PipelineRunGroupHeader from './header'

import styles from './index.module.css'

interface PipelineRunGroupPorps extends PipelineRunGroupListPorps {}

const PipelineRunGroup = memo((props: PipelineRunGroupPorps) => {
  // use componnet state to control whether to item list is visible
  // update the internal state when props has changed
  const [expandGroup, setExpandGroup] = useState(props.expandGroup || false)
  const [isExpanded, setIsExpanded] = useState(props.expandGroup || false)

  if (props.expandGroup !== expandGroup) {
    setExpandGroup(props.expandGroup || false)
    setIsExpanded(props.expandGroup || false)
  }

  const pipelineRuns = props.pipelineRuns || []

  // summarize the pipeline run status
  // memorize the result object to avoid unnecessary calculation and rerender
  const statusSummary = useMemo(
    () =>
      pipelineRuns.reduce((summary: PipelineRunStatusSummary, run: PlsPipelineRun) => {
        summary[run.status] = (summary[run.status] || 0) + 1
        return summary
      }, {}),
    [pipelineRuns]
  )

  const lastRunStatus = pipelineRuns[0]?.status
  const hasRisk = lastRunStatus !== undefined && (lastRunStatus === 'Failed' || lastRunStatus === 'Cancelled')

  // render
  return (
    <div className={`${props.className} ${styles.group}`}>
      <PipelineRunGroupHeader
        title={pipelineRuns[0]?.parameters.windowStart}
        hasRisk={hasRisk}
        statusSummary={statusSummary}
        expandGroup={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
      />

      {isExpanded &&
        pipelineRuns.map((run) => (
          <PipelineRunView
            key={run.runId}
            pipelineRun={run}
            isSelected={run.runId === props.selectedPipelineRun?.runId}
            onSelectPipelineRun={props.onSelectPipelineRun}
            onRerunPipelineRun={props.onRerunPipelineRun}
            onViewPipelineRunLog={props.onViewPipelineRunLog}
          />
        ))}
    </div>
  )
})

export default PipelineRunGroup
