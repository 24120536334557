import React, { useCallback, memo, useMemo } from 'react'
import { Select } from 'antd'

import { DataFactory } from '../../../models'

interface DataFactorySelectProps {
  className?: string
  dataFactories: DataFactory[]
  selectedDataFactory?: DataFactory
  hideProdAdf?: boolean
  hideTestAdf?: boolean
  onSelectDataFactory: (dataFactory: DataFactory) => void
}

const DataFactorySelect = memo((props: DataFactorySelectProps) => {
  const { dataFactories, onSelectDataFactory } = props

  const [prodDataFactories, testDataFactories] = useMemo(() => {
    const prodDataFactories = []
    const testDataFactories = []
    for (const adf of dataFactories) {
      const id = adf.id.toLowerCase()
      if (
        id.indexOf('/resourcegroups/databuild-vnext-test') >= 0 ||
        id.indexOf('/resourcegroups/databuild-vnext') >= 0 ||
        id.indexOf('/resourcegroups/dbsamples') >= 0 ||
        id.indexOf('/resourcegroups/pipelinestudiotesttool') >= 0
      ) {
        testDataFactories.push(adf)
      } else {
        prodDataFactories.push(adf)
      }
    }
    return [prodDataFactories, testDataFactories]
  }, [dataFactories])

  const prodDataFactoryOptions = useMemo(
    () =>
      prodDataFactories.map((dataFactory) => (
        <Select.Option key={dataFactory.name} value={dataFactory.id}>
          {dataFactory.name}
        </Select.Option>
      )),
    [prodDataFactories]
  )

  const testDataFactoryOptions = useMemo(
    () =>
      testDataFactories.map((dataFactory) => (
        <Select.Option key={dataFactory.name} value={dataFactory.id}>
          {dataFactory.name}
        </Select.Option>
      )),
    [testDataFactories]
  )

  const onChange = useCallback(
    (_, option) =>
      onSelectDataFactory({
        id: option.value,
        name: option.key,
      }),
    [onSelectDataFactory]
  )

  return (
    <Select
      className={props.className}
      placeholder='Search and select ADF'
      dropdownMatchSelectWidth={400}
      showSearch
      value={props.selectedDataFactory?.id}
      optionFilterProp='key'
      onChange={onChange}
    >
      {!props.hideProdAdf && <Select.OptGroup label='Prod data factories'>{prodDataFactoryOptions}</Select.OptGroup>}
      {!props.hideTestAdf && <Select.OptGroup label='Test data factories'>{testDataFactoryOptions}</Select.OptGroup>}
    </Select>
  )
})

export default DataFactorySelect
