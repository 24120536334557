import React, { memo } from 'react'
import { PipelineRunStatus } from '../../../models'
import { PipelineRunStatusSummary, getPipelineRunTag } from '../utils'
import styles from './index.module.css'

const StatusListWithoutInProgress: PipelineRunStatus[] = ['Succeeded', 'Failed', 'Cancelled']

interface PipelineRunGroupHeaderProps {
  className?: string
  title: string
  hasRisk: boolean
  statusSummary: PipelineRunStatusSummary
  expandGroup: boolean
  onClick: () => void
}

const PipelineRunGroupHeader = memo((props: PipelineRunGroupHeaderProps) => {
  const inProgressCount = (props.statusSummary['InProgress'] || 0) + (props.statusSummary['Queued'] || 0)

  return (
    <div
      className={`${props.className} ${styles.header} ${props.hasRisk ? styles.danger : ''}`}
      onClick={props.onClick}
    >
      <svg
        viewBox='64 64 896 896'
        focusable='false'
        width='1em'
        height='1em'
        style={{ transform: `rotate(${props.expandGroup ? 90 : 0}deg)` }}
      >
        <path d='M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z'></path>
      </svg>
      {props.title}&nbsp;
      {!props.expandGroup && inProgressCount > 0 && getPipelineRunTag('InProgress', inProgressCount)}
      {!props.expandGroup &&
        StatusListWithoutInProgress.map(
          (status) => props.statusSummary[status] && getPipelineRunTag(status, props.statusSummary[status])
        )}
    </div>
  )
})

export default PipelineRunGroupHeader
