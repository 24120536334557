import React, { memo, useCallback } from 'react'
import { Button, Dropdown, Menu, Tag, Typography } from 'antd'
import {
  CodeOutlined,
  LinkOutlined,
  MoreOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  RetweetOutlined,
  ThunderboltOutlined,
  UserOutlined,
} from '@ant-design/icons'

import { PlsPipelineRun, PlsPipelineRunTriggerType } from '../../../models'
import { openPipelineRunInADF } from '../../../utils/helpers'

import { getPipelineRunTag } from '../utils'

import styles from './index.module.css'

const { Text } = Typography

interface PipelineRunViewProps {
  className?: string
  pipelineRun: PlsPipelineRun
  isSelected: boolean
  onSelectPipelineRun?: (pipelineRun: PlsPipelineRun) => void
  onRerunPipelineRun?: (pipelineRun: PlsPipelineRun) => void
  onViewPipelineRunLog?: (pipelineRun: PlsPipelineRun) => void
}

const PipelineRunView = memo((props: PipelineRunViewProps) => {
  const { pipelineRun, onSelectPipelineRun, onViewPipelineRunLog, onRerunPipelineRun } = props

  const selectPipelineRun = useCallback(() => onSelectPipelineRun && onSelectPipelineRun(pipelineRun), [
    pipelineRun,
    onSelectPipelineRun,
  ])

  const viewLog = onViewPipelineRunLog
    ? useCallback(() => onViewPipelineRunLog && onViewPipelineRunLog(pipelineRun), [pipelineRun, onViewPipelineRunLog])
    : undefined

  const rerun = onRerunPipelineRun
    ? useCallback(() => onRerunPipelineRun && onRerunPipelineRun(pipelineRun), [pipelineRun, onRerunPipelineRun])
    : undefined

  const openInAdf = useCallback(() => openPipelineRunInADF(pipelineRun), [pipelineRun])

  const contextMenuCreator = useCallback(
    () => (
      <Menu>
        <Menu.Item className={styles.actionsSection}>Actions:</Menu.Item>
        {viewLog && (
          <Menu.Item onClick={viewLog}>
            <CodeOutlined />
            View logs
          </Menu.Item>
        )}
        <Menu.Item onClick={openInAdf}>
          <LinkOutlined />
          View details in ADF
        </Menu.Item>
        {rerun && (
          <Menu.Item onClick={rerun}>
            <RetweetOutlined />
            Rerun pipeline
          </Menu.Item>
        )}

        <Menu.Item className={styles.detailsSection}>Details:</Menu.Item>
        <Menu.Item className={styles.detail}>
          <ThunderboltOutlined />
          <Text copyable>{pipelineRun.runId}</Text>
        </Menu.Item>
        <Menu.Item className={styles.detail}>
          <UserOutlined />
          {pipelineRun.invokedBy.name}
        </Menu.Item>
        <Menu.Item className={styles.detail}>
          <PlayCircleOutlined />
          {pipelineRun.runStart}
        </Menu.Item>
        {pipelineRun.runEnd && (
          <Menu.Item className={styles.detail}>
            <PauseCircleOutlined />
            {pipelineRun.runEnd}
          </Menu.Item>
        )}
      </Menu>
    ),
    [viewLog, openInAdf, rerun, pipelineRun]
  )

  const diff = pipelineRun.durationInMs
    ? pipelineRun.durationInMs
    : Math.abs(new Date().getTime() - new Date(pipelineRun.runStart).getTime())
  const hours = Math.floor(diff / 1000 / 60 / 60)
  const mins = Math.floor((diff / 1000 / 60) % 60)

  let triggerType: PlsPipelineRunTriggerType = 'Other'
  if (pipelineRun.parameters.scenario === '0') {
    triggerType = 'Restatement'
  } else if (pipelineRun.invokedBy.name.indexOf('Manual') >= 0) {
    triggerType = 'Manual'
  } else if (pipelineRun.invokedBy.invokedByType.indexOf('PipelineActivity') >= 0) {
    triggerType = 'Pipeline'
  } else if (pipelineRun.invokedBy.invokedByType === 'TumblingWindowTrigger') {
    triggerType = 'Scheduled'
  }

  return (
    <div className={`${props.className} ${styles.item} ${props.isSelected ? styles.selected : ''}`}>
      <div className={styles.itemInner} onClick={selectPipelineRun}>
        {getPipelineRunTag(
          pipelineRun.status,
          <>
            <span className={styles.duration}>{hours}</span>&nbsp;Hours
            <span className={styles.duration}>{mins}</span>&nbsp;Minutes
          </>
        )}
        <Tag icon={<UserOutlined />} color={triggerType === 'Manual' ? 'magenta' : 'cyan'}>
          {triggerType}
        </Tag>
      </div>
      <Dropdown className={styles.menuTrigger} trigger={['hover']} overlay={contextMenuCreator}>
        <Button type='text' size='small'>
          <MoreOutlined />
        </Button>
      </Dropdown>
    </div>
  )
})

export default PipelineRunView
