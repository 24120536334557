import { combineReducers, Middleware } from 'redux'
import { configureStore as createStore, PayloadAction } from '@reduxjs/toolkit'
import { useSelector, TypedUseSelectorHook } from 'react-redux'

import dataFactoriesReducer, { fetchPipelines, FetchPipelinesPayload } from './dataFactory'
import debugPageReducer from '../pages/pipeline/store'
import restatementPageReducer from '../pages/restatement/store'

// Middleware
const PipelineListLocalStorageSerializer: Middleware = (_) => (next) => (action: PayloadAction<object>) => {
  if (action.type === fetchPipelines.fulfilled.type) {
    // persist loaded pipelines into local storage
    let typedAction = action as PayloadAction<FetchPipelinesPayload>
    localStorage.setItem(typedAction.payload.dataFactory.id, JSON.stringify(typedAction.payload.pipelines))
  }
  return next(action)
}

// Compose store
const rootReducer = combineReducers({
  dataFactoryCache: dataFactoriesReducer,
  debugPage: debugPageReducer,
  restatementPage: restatementPageReducer,
})

export default function configureStore() {
  return createStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(PipelineListLocalStorageSerializer),
  })
}

// Expose the type of composed store
export type RootState = ReturnType<typeof rootReducer>

// Expose a typed useSelector hook
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
