import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { RootState } from '../../store/index'
import { DataFactory, KeyValuePair, PlsPipeline, PlsPipelineRun, PlsPipelineRunLog, Restatement } from '../../models'

import * as Api from '../../apis'

// Store state
interface RestatementPage {
  activeTabKey: string
  nextTabKey: number
  tabs: {
    [index: string]: RestatementPageTab
  }
}

interface RestatementPageTab {
  key: string
  dataFactory?: DataFactory
  restatementId?: string
  restatement?: {
    instance: Restatement
    pipeline?: {
      instance: PlsPipeline
      run?: PlsPipelineRun
      runLogs?: PlsPipelineRunLog[]
    }
  }
  options: RestatementPageTabOption
  asyncStatus: {
    fetchingRestatement?: boolean
    fetchingRestatementRequestId?: string
    fetchingPipelineRunLogs?: boolean
    fetchingPipelineRunLogsRequestId?: string
  }
}

interface RestatementPageTabOption {
  expandPipelineRunGroup: boolean
  showTraceLog: boolean
}

// Store state generator
function NewRestatementPageTab(key: number): RestatementPageTab {
  return {
    key: `${key}`,
    options: {
      showTraceLog: true,
      expandPipelineRunGroup: false,
    },
    asyncStatus: {},
  }
}

const initialState: RestatementPage = {
  activeTabKey: '1',
  nextTabKey: 2,
  tabs: {
    '1': NewRestatementPageTab(1),
  },
}

// Async thunks
export const fetchRestatement = createAsyncThunk<
  Restatement,
  { tabKey: string; dataFactory: DataFactory; restatementId: string },
  { state: RootState }
>('restatementPage/fetchRestatement', async (arg, thunkApi) => {
  const { dataFactoryCache, restatementPage } = thunkApi.getState()
  const tab = restatementPage.tabs[arg.tabKey]
  // stop processing while another request is loading
  if (tab.asyncStatus.fetchingRestatementRequestId !== thunkApi.requestId) {
    return thunkApi.rejectWithValue(undefined)
  }
  // invoke api
  return await Api.invokeApiAndDisplayMessage<Restatement>({
    request: async () =>
      await Api.GetRestatement(arg.dataFactory.name, arg.restatementId, dataFactoryCache.accessToken),
    onError: () => thunkApi.rejectWithValue(undefined),
    loadingMessage: `Loading information for restatement: ${arg.dataFactory.name} ${arg.restatementId}`,
    successMessage: 'Successfully loaded restatement',
  })
})

export const fetchPipelineRunLogs = createAsyncThunk<
  PlsPipelineRunLog[],
  { tabKey: string; pipelineRun: PlsPipelineRun },
  { state: RootState }
>('restatementPage/fetchPipelineRunLogs', async (arg, thunkApi) => {
  const { dataFactoryCache, restatementPage } = thunkApi.getState()
  const tab = restatementPage.tabs[arg.tabKey]
  // stop processing while another request is loading
  if (tab.asyncStatus.fetchingPipelineRunLogsRequestId !== thunkApi.requestId) {
    return thunkApi.rejectWithValue(undefined)
  }
  // invoke api
  return await Api.invokeApiAndDisplayMessage<PlsPipelineRunLog[]>({
    request: async () => await Api.getPipelineRunLogs(arg.pipelineRun, dataFactoryCache.accessToken),
    onError: () => thunkApi.rejectWithValue(undefined),
    loadingMessage: `Loading data factory pipeline run logs for ${arg.pipelineRun.runId}`,
    successMessage: 'Successfully loaded data factory pipeline run logs',
  })
})

// Create actions and reducer
const restatementPageSlice = createSlice({
  name: 'restatementPage',
  initialState,
  reducers: {
    // tab operations
    addTab(state) {
      state.tabs[state.nextTabKey] = NewRestatementPageTab(state.nextTabKey)
      state.activeTabKey = `${state.nextTabKey}`
      state.nextTabKey = state.nextTabKey + 1
    },
    removeTab(state, action: PayloadAction<string>) {
      if (state.activeTabKey === action.payload) {
        const tabKeys = Object.keys(state.tabs)
        const index = tabKeys.indexOf(action.payload)
        state.activeTabKey = tabKeys[index + 1] || tabKeys[index - 1]
      }
      delete state.tabs[action.payload]
    },
    selectTab(state, action: PayloadAction<string>) {
      state.activeTabKey = action.payload
    },
    // restatement
    setRestatementId(state, action: PayloadAction<string>) {
      state.tabs[state.activeTabKey].restatementId = action.payload
    },
    // data factory scope
    selectDataFactory(state, action: PayloadAction<DataFactory>) {
      if (action.payload.id !== state.tabs[state.activeTabKey].dataFactory?.id) {
        state.tabs[state.activeTabKey].dataFactory = action.payload
      }
    },
    // pipeline scope
    selectPipeLine(state, action: PayloadAction<PlsPipeline>) {
      const restatement = state.tabs[state.activeTabKey].restatement
      if (restatement && restatement.pipeline?.instance.name !== action.payload.name) {
        restatement.pipeline = { instance: action.payload }
      }
    },
    // pipeline run scope
    selectPipelineRun(state, action: PayloadAction<PlsPipelineRun>) {
      const debugTab = state.tabs[state.activeTabKey]
      const pipeline = debugTab.restatement?.pipeline
      if (pipeline && pipeline.run?.runId !== action.payload.runId && !debugTab.asyncStatus.fetchingPipelineRunLogs) {
        pipeline.run = action.payload
      }
    },
    // options
    setOption<K extends keyof RestatementPageTabOption, V extends RestatementPageTabOption[K]>(
      state: RestatementPage,
      action: PayloadAction<KeyValuePair<K, V>>
    ) {
      const tab = state.tabs[state.activeTabKey]
      tab.options[action.payload.key] = action.payload.value
    },
  },
  extraReducers: (builder) => {
    // load restatement
    builder.addCase(fetchRestatement.pending, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (!tab.asyncStatus.fetchingRestatement) {
        tab.asyncStatus.fetchingRestatement = true
        tab.asyncStatus.fetchingRestatementRequestId = action.meta.requestId
      }
    })
    builder.addCase(fetchRestatement.fulfilled, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      tab.restatement = { instance: action.payload }
      tab.asyncStatus.fetchingRestatement = false
      tab.asyncStatus.fetchingRestatementRequestId = undefined
    })
    builder.addCase(fetchRestatement.rejected, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (tab.asyncStatus.fetchingRestatementRequestId === action.meta.requestId) {
        tab.asyncStatus.fetchingRestatement = false
        tab.asyncStatus.fetchingRestatementRequestId = undefined
      }
    })
    // load pipeline run logs
    builder.addCase(fetchPipelineRunLogs.pending, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (!tab.asyncStatus.fetchingPipelineRunLogs) {
        tab.asyncStatus.fetchingPipelineRunLogs = true
        tab.asyncStatus.fetchingPipelineRunLogsRequestId = action.meta.requestId
      }
    })
    builder.addCase(fetchPipelineRunLogs.fulfilled, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (tab.restatement && tab.restatement.pipeline) {
        tab.restatement.pipeline.runLogs = action.payload
      }
      tab.asyncStatus.fetchingPipelineRunLogs = false
      tab.asyncStatus.fetchingPipelineRunLogsRequestId = undefined
    })
    builder.addCase(fetchPipelineRunLogs.rejected, (state, action) => {
      const tab = state.tabs[action.meta.arg.tabKey]
      if (tab.asyncStatus.fetchingPipelineRunLogsRequestId === action.meta.requestId) {
        tab.asyncStatus.fetchingPipelineRunLogs = false
        tab.asyncStatus.fetchingPipelineRunLogsRequestId = undefined
      }
    })
  },
})

export const {
  // tab actions
  addTab,
  removeTab,
  selectTab,
  // restatement
  setRestatementId,
  // data factory actions
  selectDataFactory,
  // pipeline actions
  selectPipeLine,
  // pipeline run actions
  selectPipelineRun,
  // tab options
  setOption,
} = restatementPageSlice.actions

export default restatementPageSlice.reducer
