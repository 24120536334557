import React, { useCallback, memo, useState } from 'react'
import { Button, Empty, Form, InputNumber, Popover, Spin, Tooltip, Select } from 'antd'
import { ColumnHeightOutlined, FilterOutlined, ReloadOutlined, VerticalAlignMiddleOutlined } from '@ant-design/icons'

import { DefaultOption } from '../../../utils/consts'
import { PlsPipelineRun, PlsPipelineRunFilter, KeyValuePair } from '../../../models'

import Panel from '../../panel'
import PipelineRunGroupList from '../pipelineRunGroupList'

import styles from './index.module.css'

interface PipelineRunPanelProps {
  className?: string
  isLoading?: boolean
  pipelineRuns?: PlsPipelineRun[]
  selectedPipelineRun?: PlsPipelineRun
  onSelectPipelineRun?: (pipelineRun: PlsPipelineRun) => void
  onViewPipelineRunLogs?: (pipelineRun: PlsPipelineRun) => void
  onRerunPipelineRun?: (pipelineRun: PlsPipelineRun) => void
  onFetchPipelineRuns?: () => void
  // filters
  pipelineRunFilter?: PlsPipelineRunFilter
  onUpdatePipelineRunFilter?: <K extends keyof PlsPipelineRunFilter, V extends PlsPipelineRunFilter[K]>(
    arg: KeyValuePair<K, V>
  ) => void
  // look back days control
  lookBackDays?: number
  onUpdateLookBackDays?: (lookBackDays: number) => void
  // display options
  expandPipelineRunGroup?: boolean
  onUpdateExpandPipelineRunGroup?: (expandPipelineRunGroup: boolean) => void
}

const PipelineRunPanel = memo((props: PipelineRunPanelProps) => {
  // visibility options
  const [isFilterFormVisible, setIsFilterFormVisible] = useState(false)

  const { onUpdatePipelineRunFilter } = props

  const onSelectTriggerFilter = useCallback(
    (value) =>
      onUpdatePipelineRunFilter &&
      onUpdatePipelineRunFilter({ key: 'trigger', value: value === DefaultOption ? undefined : value }),
    [onUpdatePipelineRunFilter]
  )

  const onSelectStatusFilter = useCallback(
    (value) =>
      onUpdatePipelineRunFilter &&
      onUpdatePipelineRunFilter({ key: 'status', value: value === DefaultOption ? undefined : value }),
    [onUpdatePipelineRunFilter]
  )

  const onUpdateLookBackDays = useCallback(
    (lookBackDays: number | string | undefined) => {
      if (typeof lookBackDays === 'number') {
        props.onUpdateLookBackDays && props.onUpdateLookBackDays(lookBackDays as number)
      }
    },
    [props]
  )

  const onToggleExpandPipelineRunGroup = () => {
    props.onUpdateExpandPipelineRunGroup && props.onUpdateExpandPipelineRunGroup(!props.expandPipelineRunGroup)
  }

  // consts
  const hasPipelineRuns = props.pipelineRuns && props.pipelineRuns.length > 0

  const filterForm = (
    <Form className={styles.filterForm} layout='vertical'>
      <Form.Item label='Trigger:'>
        <Select value={props.pipelineRunFilter?.trigger || DefaultOption} onSelect={onSelectTriggerFilter}>
          <Select.Option key={DefaultOption} value={DefaultOption}>
            All triggers
          </Select.Option>
          {Array.from(new Set(props.pipelineRuns?.map((run) => run.invokedBy.name))).map((trigger) => (
            <Select.Option key={trigger} value={trigger}>
              {trigger}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item label='Status:'>
        <Select value={props.pipelineRunFilter?.status || DefaultOption} onSelect={onSelectStatusFilter}>
          <Select.Option key={DefaultOption} value={DefaultOption}>
            All status
          </Select.Option>
          <Select.Option key='1' value='Succeeded'>
            Succeeded
          </Select.Option>
          <Select.Option key='2' value='InProgress'>
            InProgress
          </Select.Option>
          <Select.Option key='3' value='Failed'>
            Failed
          </Select.Option>
          <Select.Option key='4' value='Cancelled'>
            Cancelled
          </Select.Option>
        </Select>
      </Form.Item>
    </Form>
  )

  return (
    <Panel
      className={props.className}
      title='Pipeline runs'
      menu={
        <>
          {props.onUpdateExpandPipelineRunGroup && (
            <Tooltip title='Toggle pipeline run groups' placement='top'>
              <Button
                className={styles.menuItem}
                icon={props.expandPipelineRunGroup ? <VerticalAlignMiddleOutlined /> : <ColumnHeightOutlined />}
                onClick={onToggleExpandPipelineRunGroup}
              />
            </Tooltip>
          )}
          {props.onUpdatePipelineRunFilter && (
            <Tooltip title='Filter pipeline runs' placement='top'>
              <Popover
                title='Filter pipeline runs'
                trigger='click'
                visible={isFilterFormVisible}
                onVisibleChange={() => setIsFilterFormVisible(!isFilterFormVisible)}
                content={filterForm}
              >
                <Button className={styles.menuItem} icon={<FilterOutlined />} />
              </Popover>
            </Tooltip>
          )}
          {props.onFetchPipelineRuns && (
            <Tooltip title='Reload pipeline runs' placement='top'>
              <Button className={styles.menuItem} icon={<ReloadOutlined />} onClick={props.onFetchPipelineRuns} />
            </Tooltip>
          )}
          {props.onUpdateLookBackDays && (
            <div className={styles.lookBackDays}>
              <span>Look back </span>
              <InputNumber
                className={styles.inputNumber}
                type='number'
                size='small'
                defaultValue={props.lookBackDays}
                onChange={onUpdateLookBackDays}
              />
              <span> days</span>
            </div>
          )}
        </>
      }
      content={
        props.isLoading || hasPipelineRuns ? (
          <div className={styles.list}>
            <Spin size='large' spinning={props.isLoading}>
              <PipelineRunGroupList
                pipelineRuns={props.pipelineRuns || []}
                selectedPipelineRun={props.selectedPipelineRun}
                expandGroup={props.expandPipelineRunGroup}
                pipelineRunFilter={props.pipelineRunFilter}
                onSelectPipelineRun={props.onSelectPipelineRun}
                onRerunPipelineRun={props.onRerunPipelineRun}
                onViewPipelineRunLog={props.onViewPipelineRunLogs}
              />
            </Spin>
          </div>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
    />
  )
})

export default PipelineRunPanel
