import { useEffect, useState } from 'react'
import { PlsPipeline, PlsPipelineRun } from '../models'

export function useDebounce<T>(value: T, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay)
    return () => clearTimeout(timer)
  }, [value, delay])

  return debouncedValue
}

export function delayAfter<T>(ms: number, result: T): Promise<T> {
  return new Promise<T>((resolve) => {
    setTimeout(() => resolve(result), ms)
  })
}

/**
 * Uses canvas.measureText to compute and return the width of the given text of given font in pixels.
 *
 * @param {String} text The text to be rendered.
 * @param {String} font The css font descriptor that text is to be rendered with (e.g. "bold 14px verdana").
 *
 * @see https://stackoverflow.com/questions/118241/calculate-text-width-with-javascript/21015393#21015393
 */
const canvas = document.createElement('canvas')
export function getTextWidth(text: string, font: string): number | undefined {
  var context = canvas.getContext('2d')
  if (context) {
    context.font = font
    var metrics = context.measureText(text)
    return metrics.width
  }
  return undefined
}

export function getLongerText(first: string, second: string): string {
  return first.length > second.length ? first : second
}

export function openPipelineInADF(pipeline: PlsPipeline) {
  const url = `https://ms-adf.azure.com/authoring/pipeline/${pipeline.name}?factory=${pipeline.id}`
  window.open(url)
}

export function openPipelineRunInADF(pipelineRun: PlsPipelineRun) {
  const url = `https://ms-adf.azure.com/monitoring/pipelineruns/${pipelineRun.runId}?factory=${pipelineRun.id}`
  window.open(url)
}
