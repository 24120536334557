import React, { useCallback, useMemo, useState, memo } from 'react'
import { useDispatch, batch } from 'react-redux'
import { Button, Row, Col, Modal, Input, Tooltip, Form, message } from 'antd'
import { ReloadOutlined } from '@ant-design/icons'

import { DataFactory, PlsPipeline, PlsPipelineRun } from '../../models'
import { useTypedSelector } from '../../store'
import {
  setRestatementId,
  selectDataFactory,
  selectPipeLine,
  selectPipelineRun,
  fetchRestatement,
  fetchPipelineRunLogs,
  setOption,
} from './store'

import RestatementPanel from '../../components/restatement/restatementPanel'
import PipelineRunPanel from '../../components/pipelineRun/pipelineRunPanel'
import PipelineRunLogPanel from '../../components/pipelineRunLog/pipelineRunLogPanel'
import DataFactorySelect from '../../components/dataFactory/dataFactorySelect'

import styles from './index.module.css'

interface DebugTabViewProps {
  tabKey: string
}

const DebugTabView = memo((props: DebugTabViewProps) => {
  // state
  const [isModalVisible, setIsModalVisible] = useState(false)

  const toggleModal = () => setIsModalVisible(!isModalVisible)

  // store
  const dataFactories = useTypedSelector((state) => state.dataFactoryCache.dataFactories)
  const tab = useTypedSelector((state) => state.restatementPage.tabs[props.tabKey])
  const { dataFactory, restatementId } = tab
  const restatement = tab.restatement?.instance
  const pipeline = tab.restatement?.pipeline?.instance
  const pipelineRun = tab.restatement?.pipeline?.run
  const pipelineRunLogs = tab.restatement?.pipeline?.runLogs

  const pipelines = useMemo(() => {
    const pipelines: PlsPipeline[] = []
    for (const trigger of restatement?.triggers || []) {
      for (const pipelineName of trigger.triggeredAdfPipelineNames) {
        if (pipelines.find((p) => p.name === pipelineName)) {
          continue
        }
        const dataFactory = restatement?.dataFactories.find(
          (dataFactory) => dataFactory.dataFactoryName === trigger.dataFactoryName
        )
        const pipelineStatus = (restatement?.pipelineStatus || {})[pipelineName]
        const pipeline: PlsPipeline = {
          id: `/subscriptions/${dataFactory?.subscriptionId}/resourceGroups/${dataFactory?.resourceGroup}/providers/Microsoft.DataFactory/factories/${dataFactory?.dataFactoryName}/pipelines/${pipelineName}`,
          name: pipelineName,
          shortName: pipelineName.substring(pipelineName.indexOf('_') + 1),
          status: pipelineStatus?.status,
          gaps: pipelineStatus?.windowStartGaps,
        }
        pipelines.push(pipeline)
      }
    }
    return pipelines.sort((a, b) => (a.name > b.name ? 1 : -1))
  }, [restatement])

  const pipelineRuns = useMemo(() => {
    let pipelineRuns: PlsPipelineRun[] = []
    for (const trigger of Object.values(restatement?.triggeredPipelineRuns || {})) {
      pipelineRuns = pipelineRuns.concat(trigger[pipeline?.name || ''] || [])
    }
    return pipelineRuns.sort((a, b) =>
      a.parameters.windowStart > b.parameters.windowStart ||
      (a.parameters.windowStart === b.parameters.windowStart && a.runStart > b.runStart)
        ? -1
        : 1
    )
  }, [restatement, pipeline])

  // use dispatch
  const dispatch = useDispatch()

  const onSelectDataFactory = useCallback((dataFactory: DataFactory) => dispatch(selectDataFactory(dataFactory)), [
    dispatch,
  ])

  const onChangeRestatementId: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => dispatch(setRestatementId(e.target.value)),
    [dispatch]
  )

  const onLoadRestatement = useCallback(() => {
    if (dataFactory && restatementId) {
      setIsModalVisible(false)
      dispatch(fetchRestatement({ tabKey: props.tabKey, dataFactory: dataFactory, restatementId: restatementId }))
    } else {
      message.info('Please fill the form.')
    }
  }, [dispatch, setIsModalVisible, props.tabKey, dataFactory, restatementId])

  const onSelectPipeline = useCallback((pipeline: PlsPipeline) => dispatch(selectPipeLine(pipeline)), [dispatch])

  const onSelectPipelineRunAndViewLogs = useCallback(
    (run: PlsPipelineRun) =>
      batch(() => {
        dispatch(selectPipelineRun(run))
        dispatch(fetchPipelineRunLogs({ tabKey: props.tabKey, pipelineRun: run }))
      }),
    [dispatch, props.tabKey]
  )

  const onFetchPipelineRunLogs = useCallback(() => {
    if (pipelineRun) {
      dispatch(fetchPipelineRunLogs({ tabKey: props.tabKey, pipelineRun: pipelineRun }))
    }
  }, [dispatch, props.tabKey, pipelineRun])

  const onUpdateExpandPipelineRunGroup = useCallback(
    (expandPipelineRunGroup: boolean) =>
      dispatch(setOption({ key: 'expandPipelineRunGroup', value: expandPipelineRunGroup })),
    [dispatch]
  )

  const onUpdateShowTraceLog = useCallback(
    (showTraceLog: boolean) => dispatch(setOption({ key: 'showTraceLog', value: showTraceLog })),
    [dispatch]
  )

  const restatementPanelMenu = (
    <>
      <Tooltip title='Refresh' placement='top'>
        <Button icon={<ReloadOutlined />} onClick={onLoadRestatement} />
      </Tooltip>
      <Button className={styles.item} onClick={toggleModal}>
        Select restatement
      </Button>
      <Modal
        title='Select restatement'
        visible={isModalVisible}
        okText='Load'
        onOk={onLoadRestatement}
        onCancel={toggleModal}
      >
        <Form layout='vertical'>
          <Form.Item label='Data factory:' required requiredMark>
            <DataFactorySelect
              className={styles.select}
              dataFactories={dataFactories}
              selectedDataFactory={dataFactory}
              onSelectDataFactory={onSelectDataFactory}
              hideTestAdf
            />
          </Form.Item>
          <Form.Item label='Restatement id:' required requiredMark>
            <Input defaultValue={restatementId} onChange={onChangeRestatementId} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )

  // render
  return (
    <Row className={styles.tabContent}>
      <Col flex='5' className={styles.restatementColumn}>
        <RestatementPanel
          className={styles.panel}
          isLoading={tab.asyncStatus.fetchingRestatement || false}
          restatement={restatement}
          pipelines={pipelines}
          selectedPipeline={pipeline}
          onSelectPipeline={onSelectPipeline}
          menu={restatementPanelMenu}
        />
      </Col>
      <Col flex='7' className={styles.pipelineRunColumn}>
        <PipelineRunPanel
          className={styles.panel}
          isLoading={false}
          pipelineRuns={pipelineRuns}
          selectedPipelineRun={pipelineRun}
          onSelectPipelineRun={onSelectPipelineRunAndViewLogs}
          onViewPipelineRunLogs={onSelectPipelineRunAndViewLogs}
          expandPipelineRunGroup={tab.options.expandPipelineRunGroup}
          onUpdateExpandPipelineRunGroup={onUpdateExpandPipelineRunGroup}
        />
      </Col>
      <Col flex='14' className={styles.pipelineRunLogColumn}>
        <PipelineRunLogPanel
          className={styles.panel}
          isLoading={tab.asyncStatus.fetchingPipelineRunLogs || false}
          pipelineRunLogs={pipelineRunLogs}
          onFetchPipelineRunLogs={onFetchPipelineRunLogs}
          showTraceLog={tab.options.showTraceLog}
          onUpdateShowTraceLog={onUpdateShowTraceLog}
        />
      </Col>
    </Row>
  )
})

export default DebugTabView
