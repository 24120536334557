const dev = {
  PLSApiDomain: {
    HOST: 'https://db2debugapi-restatement.azurewebsites.net',
  },
}

const prod = {
  PLSApiDomain: {
    HOST: 'https://db2debugapi-restatement.azurewebsites.net',
  },
}

// by default the process.env evaluation is in build time
const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev

export default {
  // Add common config values here
  ...config,
}
