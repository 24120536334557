import React, { memo, useMemo } from 'react'
import { PlsPipelineRun, PlsPipelineRunFilter } from '../../../models'
import PipelineRunGroup from '../pipelineRunGroup'
import styles from './index.module.css'

// internal types
interface PipelineRunGroupMap {
  [index: string]: PlsPipelineRun[]
}

// component for group list
export interface PipelineRunGroupListPorps {
  className?: string
  pipelineRuns?: PlsPipelineRun[]
  selectedPipelineRun?: PlsPipelineRun
  expandGroup?: boolean
  pipelineRunFilter?: PlsPipelineRunFilter
  onSelectPipelineRun?: (pipelineRun: PlsPipelineRun) => void
  onRerunPipelineRun?: (pipelineRun: PlsPipelineRun) => void
  onViewPipelineRunLog?: (pipelineRun: PlsPipelineRun) => void
}

const PipelineRunGroupList = memo((props: PipelineRunGroupListPorps) => {
  const { trigger, status } = props.pipelineRunFilter || {}

  const runGroups: PipelineRunGroupMap = useMemo(
    () =>
      (props.pipelineRuns || [])
        .filter((run) => !trigger || trigger === run.invokedBy.name)
        .filter((run) => !status || status === run.status || (status === 'InProgress' && run.status === 'Queued'))
        .reduce((tempRunGroups: PipelineRunGroupMap, run: PlsPipelineRun) => {
          ;(tempRunGroups[run.parameters.windowStart] = tempRunGroups[run.parameters.windowStart] || []).push(run)
          return tempRunGroups
        }, {}),
    [props.pipelineRuns, trigger, status]
  )

  return (
    <div className={`${props.className} ${styles.list}`}>
      {Object.keys(runGroups).map((windowStart) => (
        <PipelineRunGroup
          key={windowStart}
          pipelineRuns={runGroups[windowStart]}
          selectedPipelineRun={props.selectedPipelineRun}
          expandGroup={props.expandGroup}
          onSelectPipelineRun={props.onSelectPipelineRun}
          onRerunPipelineRun={props.onRerunPipelineRun}
          onViewPipelineRunLog={props.onViewPipelineRunLog}
        />
      ))}
    </div>
  )
})

export default PipelineRunGroupList
